<template>
    <div class="train-cell component flex-row flex-j-between flex-a-center tap" @click="onClick">
            <lazy-img class="image" :src="item.TitleImgUrl"></lazy-img>
            <div class="flex-1 flex-col flex-j-between flex-a-start">
                <div class="flex-row flex-j-between flex-a-start" style="width: 100%">
                    <div class="title line-num-2">{{item.TitleName}}</div>
                </div>
                <div>
                    <span v-if="item.ItemType==2 && item.TrainType==1" style="color: #f1676d;">免费培训</span>
                    <span v-if="item.ItemType==2 && item.TrainType==2" style="color: #4eabe8;">商业培训</span>
                </div>
                <div class="time">{{item.timeText}}</div>
                <div class="flex-row flex-j-between flex-a-start" style="width: 100%">
                    <!-- <div class="time" style="span-align:left;">发布者:{{item.TrainAuthor}}</div> -->
                    <div class="time" style="text-align:left;">浏览:{{item.ViewTimes}}</div>
                </div>
            </div>
    </div>
</template>

<script>
// train-cell
import LazyImg from "@/components/lazy-img";
export default {
    name: "train-cell",
    components: {LazyImg},
    props: ['item'],
    data() {
        return {}
    },
    mounted() {
    },
    methods: {
        onClick() {
            this.$router.push(`/train-detail?id=${this.item.Id}`)
        }
    }
}
</script>

<style scoped lang="less">
.train-cell {
    transition: all 0.3s ease;
    &:hover {
        box-shadow: 0 0.12rem 0.12rem 0 rgba(0, 0, 0, 0.1);
    }
}
.component {
    padding: .20rem;
    background-color: #fff;
    margin-bottom: .20rem;
    border-radius: .20rem;
    text-align: left;
}

.component .image {
    width: 3.60rem;
    height: 2.60rem;
    /* background-color: orange; */
    border-radius: .12rem;
    margin-right: .20rem;
    overflow: hidden;
}

.component .flex-1 {
    height: 2.00rem;
}
.component .flex-1 .time {
    width: 3.00rem;
    font-size: .36rem;
    color: #999999;
    text-align: left;
}

.component .flex-1 .title {
    font-size: .46rem;
    flex: 1;
    height: .80rem;
}

.component .tag {
    display: inline-block;
    margin-right: .20rem;
    margin-top: .10rem;
    padding: .06rem .10rem;
    border-radius: .04rem;
    background: #57b8c1;
    color: #fff;
    font-size: .34rem;
}
</style>
